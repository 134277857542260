import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { CloudLoginService } from "cloud-labs-core";
import { DxDataGridComponent } from "devextreme-angular";
import {
  Branch,
  Inventory,
  MappingItem,
  PosAccount,
  WareHouse,
} from "../models/global-model";
import {
  AccountingType,
  Invoice,
  InvoiceDeliveryInofo,
  invoiceLog,
  invoiceRow,
  InvoiceService,
  logType,
} from "../services/invoice.service";
import { GlobalsService } from "../../services/globals.service";
import { ModalService } from "projects/cloud-components/src/lib/modal/modal.service";
import moment from "moment";
import DataSource from "devextreme/data/data_source";
import { PublicApiService } from "src/app/services/public-service/public-api.service";
import { InvoiceSettingsService } from "../services/invoice-settings.service";
import CustomStore from "devextreme/data/custom_store";
import { InventoryService } from "src/app/services/inventory/inventory.service";

@Component({
  selector: "app-backoffice-transfer",
  templateUrl: "./backoffice-transfer.component.html",
  styleUrls: ["./backoffice-transfer.component.scss"],
})
export class BackofficeTransferComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  branchCode: string = "";
  loadingMessage: string = "Yükleniyor...";
  loadingVisible: boolean = false;
  accountCode: string = "";
  inoviceError = "";
  defaultAccounting = 0
  selectedWarehouse: WareHouse = new WareHouse();
  selectedBranch: Branch = new Branch();
  isValid: boolean = true;
  accountMapping: MappingItem = new MappingItem();
  accountMapData: MappingItem = new MappingItem();
  units = [[]];
  branchList: Branch[] = []
  activeBranches: number[] = []
  activeLicances: any

  accountList: PosAccount[] = [];
  dxAccount: DataSource;
  realInvoiceDate;
  invoiceDate: Date = new Date();
  wareHouseList: WareHouse[] = [];
  inventoryList: Inventory[] = [];
  inventoryDataSource: any;
  lookupDataSource = {};
  kerzzStockCodeOptions = {
    minSearchLength: 3,
    searchMode: "contains",
    searchEnabled: true,
    placeholder: "Arama için en az 3 harf giriniz...",
    pageLoadMode: "nextButton",
  };
  selectedTab: "invoice" | "preview" = "invoice";

  tabBarItems: TabItem[] = [
    {
      id: "invoice",
      title: "Fatura Ayarları",
    },
    {
      id: "preview",
      title: "Fatura Önizleme",
    },
  ];

  accountingTypes: AccountingType[] = [
    {
      id: 0,
      name: "Malzeme"
    },
    {
      id: 1,
      name: "Gider"
    }

  ]

  selectTabItem(tabItem: TabItem) {
    this.selectedTab = tabItem.id;
    if (tabItem.id == "preview") {
      this.previewInvoice();
    }
  }

  onEditorPreparing(e) {
    if (e.dataField === "externalCode" && e.parentType === "dataRow") {
      let standardHandler = e.editorOptions.onValueChanged;
      e.editorOptions.onValueChanged = (x) => {
        this.getPosInventoryUnits(x.value, e.row.data, e.row.rowIndex);
        standardHandler(x); // Calls the standard handler to save the edited value
      };
    }
  }

  getPosInventoryUnits(itemCode, item: any, rowIndex: number) {
    this.invoiceService
      .getPosInventoryUnits(itemCode)
      .subscribe((res: Unit[]) => {
        if (res.length > 0) {
          let data = res[0];
          if (res.every((el) => el.unitCode != item.unitName)) {
            item.unitName = data.unitCode;
          }
          this.units[rowIndex] = res.map((el) => ({
            id: el.unitCode,
            name: el.unitName,
          }));
          console.log("", this.units);
        }
      });
  }

  constructor(
    public invoiceService: InvoiceService,
    private ls: CloudLoginService,
    private glb: GlobalsService,
    private modalService: ModalService,
    private publicService: PublicApiService,
    private iSet: InvoiceSettingsService,
    private inventoryService : InventoryService
  ) {
    this.activeLicances = this.ls.userInfo.licances.find(x => x.licanceId === this.glb.activeLicance)
    if (this.activeLicances.branchCodes) {
      if (this.activeLicances.branchCodes.length > 0) {
        this.activeLicances.branchCodes.map(x => {
          this.activeBranches.push(x)
        })
        this.activeBranches.map(el => {
          if (this.invoiceService.branchList.filter(v => v.branchCode === el.toString()).length > 0) {
            this.branchList.push(this.invoiceService.branchList.find(b => b.branchCode === el.toString()))
          }

        })
        console.log('this.branchList', this.branchList)

      }
      else {
        this.branchList = this.invoiceService.branchList
      }
    }
    else {
      this.branchList = this.invoiceService.branchList
    }
    // console.log()
    // this.activeLicances = this.ls.userInfo.licances.find(x => x.licanceId === this.glb.activeLicance)
    // if (this.activeLicances)
    // {
    //   if (this.activeLicances.branchCodes)
    //   {
    //    if (this.activeLicances.branchCodes.length > 0) {
    //     this.activeLicances.branchCodes.map(x=> {
    //       this.branchList.push(this.invoiceService.branchList.find(c=>c.branchCode === x))
    //     })
    //    }
    //    else
    //    {
    //     this.branchList =  this.invoiceService.branchList
    //    }
    //     console.log('this.activeBranches',this.activeBranches)
    //   }
    //   else
    //   {
    //     this.branchList =  this.invoiceService.branchList
    //   }

    // }

    this.lookupDataSource = new CustomStore({
      key: "inventroyCode",
      load: (loadOptions) => {
        console.log(loadOptions);
        return this.getInvnetoryList(this.branchCode, loadOptions.searchValue);
      },
      byKey: (key) => {
        console.log(key);
        return this.getInvnetoryList(this.branchCode, key);
      },
    });

    this.dxAccount = new DataSource({
      store: new CustomStore({
        key: "accountCode",
        load: (loadOptions) => {
          console.log("accountCode", loadOptions, this.accountCode);
          return this.getAccountList(
            this.branchCode,
            loadOptions.searchValue ?? this.accountCode
          );
        },
        byKey: (key) => {
          console.log(key);
          return this.getAccountList(this.branchCode, key);
        },
      }),
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoice) {
      console.log("convert", this.invoice);

    }
  }

  @Input("invoice") set setInvoice(value: Invoice) {
    this.invoice = value;
    console.log("invoice", this.invoice);
    // this.invoice.invoiceRows.map(x => {
    //   // x.total = this.calculateCellValueNetTutar(x) + this.roundNumber(this.calculateCellValueKDVTutar(x))
    //   // x.grandTotal = this.calculateCellValueToplamTutar(x)
    //   // x.taxTotal = this.roundNumber(this.calculateCellValueKDVTutar(x))
    // })
    this.clearFormElements();
    this.accountCode =
      this.invoice.account.externalCode || this.invoice.account.taxNumber;
    if (this.invoiceService.branchList.length > 0) {
      console.log("branchList", this.invoiceService.branchList.length);
      this.branchCode = this.invoice.branchCode;
      this.fillData(this.branchCode);
      this.invoiceDate = value.invoiceDate;
      this.invoice.invoiceRows.map((x) => {
        x.warehouseCode = 0;
      });
      this.getInventoryMapping();
    }
  }

  invoice: Invoice;
  @Input() invoiceEntry = false;
  @Output() formClose = new EventEmitter<string>();

  ngOnInit(): void {
    this.initUnits();
    this.getHistory('asda')
  }

  async branchChanged(e) {
    this.branchCode = e.selectedItem.branchCode;
    this.invoice.branchCode = e.selectedItem.branchCode;
    await this.getWarehouseList(e.selectedItem.branchCode);

    // this.fillData(this.branchCode)
  }

  accountingTypeChanced(e) {
    this.invoice.accountingType = e.selectedItem.id
  }

  async fillData(branchCode) {
    await this.getAccountMapping();
    await this.getInvnetoryList(0);
    await this.getWarehouseList(branchCode);
  }

  warehouseChanged(e) {
    this.selectedWarehouse = e.selectedItem;
  }

  accountChanged(e) {
    debugger
    this.accountCode = e.selectedItem?.accountCode;
    this.invoice.account.externalCode = this.accountCode;
  }

  clearFormElements() {
    this.accountCode = "";
    this.selectedWarehouse = new WareHouse();
    // this.selectedBranch = new Branch()
  }

  getWarehouseList(branchCode) {
    this.invoiceService.getWarehouseList(branchCode).subscribe(async (x) => {
      this.wareHouseList = x;
      await this.getInventoryMapping();
    });
  }

  getAccountList(branchCode, searchKey = "") {
    return new Promise((resolve, reject) => {
      if (!searchKey) {
        searchKey =
          this.invoice.account.externalCode || this.invoice.account.taxNumber;
      }
      this.invoiceService.getAccountList(searchKey).subscribe((x) => {
        this.accountList = x;
        // if (x.length> 0)
        // {
        //   this.accountCode = x[0].accountCode
        // }

        resolve(x);
      });
    });
  }

  getInvnetoryList(branchCode, searchKey = "") {
    if (!searchKey) {
      searchKey = "";
    }
    return new Promise((resolve, reject) => {
      // this.loadingVisible = true;
      // if(!searchKey)
      // {
      //   searchKey = this.invoice.invoiceRows[0].code
      // }
      this.invoiceService
        .getInventoryList(branchCode, searchKey)
        .subscribe((x) => {
          this.inventoryList = x;
          // this.loadingVisible = false;
          // this.getInventoryMapping();
          resolve(x);
        });
    });
  }
  onValueChangedOperationID(e) {
    console.log("onValueChangedOperationID", e);
  }

  isPreviewFetched = false;
  pdf = "";

  previewInvoice() {
    if (this.isPreviewFetched) {
      setTimeout(() => {
        let previewDiv = document.getElementById("pdf-preview-div");
        previewDiv.innerHTML = this.pdf;
      }, 500);

      return;
    }
    this.loadingVisible = true;
    let uuids = [];

    // for (const iterator of this.selectedInovices) {
    uuids.push(this.invoice.uuid);
    // }

    this.invoiceService.requestPdfInvoices(uuids).subscribe(
      (o) => {
        const previewDiv = document.getElementById("pdf-preview-div");
        this.loadingVisible = false;
        // this.modalService.open("inoviceModal", "top-modal");
        previewDiv.innerHTML = `<embed src='data:application/pdf;base64,${Object.assign(o).pdf
          }' type="application/pdf" width="100%" height="600px" />`;
        this.addLog("log", this.invoice, "fatura-ön-izleme", "başarılı");
        this.isPreviewFetched = true;
        this.pdf = `<embed src='data:application/pdf;base64,${Object.assign(o).pdf
          }' type="application/pdf" width="100%" height="600px" />`;
      },
      (e) => {
        this.ShowError(e.error.message);
      }
    );
  }

  private ShowError(e) {
    console.log(e);
    this.loadingVisible = false;
    this.inoviceError = e;
    let div = document.createElement("div");
    div.classList.add("error-content", "all-center");
    div.innerHTML = e;
    let previewDiv = document.getElementById("pdf-preview-div");
    previewDiv.innerHTML = div.outerHTML;
    //this.modalService.open("inoviceError");
  }

  formValid() {
    if (
      this.invoice.invoiceRows.filter(
        (x) => x.externalCode == "" || x.externalCode == undefined
      ).length > 0
    ) {
      this.glb.notice("Lütfen Eksik Alanları Doludurunuz!", "warning");
      return false;
    } else if (
      this.invoice.invoiceRows.filter((x) => x.warehouseCode == undefined)
        .length > 0
    ) {
      this.glb.notice("Lütfen Eksik Alanları Doludurunuz!", "warning");
      return false;
    } else {
      return true;
    }
  }

  addLog(lType: logType, inv: Invoice, title, message) {
    let log: invoiceLog = new invoiceLog();
    log.type = lType;
    log.title = title;
    log.message = message;
    log.userId = this.ls.userInfo.id;
    log.uuid = inv.uuid;
    if (!inv.logs) {
      inv["logs"] = [];
    }
    inv.logs.push(log);
    this.invoiceService.addInvoiceLog(log).subscribe((x: any) => { });
  }

  getAccountMapping() {
    this.invoiceService
      .getMappingList(
        this.branchCode,
        this.invoice.account.taxNumber,
        "account"
      )
      .subscribe((o) => {
        if (o.length > 0) {
          this.accountMapData = o[0];
          if (!this.invoice.account.externalCode) {
            this.invoice.account.externalCode =
              this.accountMapData.externalCode;
            this.accountCode = this.accountMapData.externalCode;
          }
          this.dxAccount.reload();
        }
        // if (o.length > 0) {
        //   debugger;
        //   this.accountMapData = o[0];
        //   this.invoiceService.getAccountList(o[0].externalCode).subscribe((x) => {
        //     console.log(x);
        //     this.accountList = x;
        //     this.dxAccount = new DataSource({
        //       paginate: true,
        //       pageSize: 10,
        //       store: {
        //         type: "array",
        //         data: x,
        //         key: "accountCode",
        //       },
        //     });
        //     this.accountCode = this.accountList.find(
        //       (a) => a.accountCode == o[0].externalCode
        //     ).accountCode;
        //   });
        // } else {
        //   this.accountMapData = o[0];
        //   this.invoiceService.getAccountList(this.invoice.account.accountName).subscribe((x) => {
        //     this.accountList = x;
        //     this.dxAccount = new DataSource({
        //       paginate: true,
        //       pageSize: 10,
        //       store: {
        //         type: "array",
        //         data: x,
        //         key: "accountCode",
        //       },
        //     });
        //     this.accountCode = this.accountList.find(
        //       (a) => a.accountCode == o[0].externalCode
        //     ).accountCode;
        //   });
        // }
      });
  }

  accountValueChanged(e) {
    // console.log(e);
    // console.log(this.accountCode);
  }

  initUnits() {
    this.units = [this.mockUnits];
  }

  getStockUnits(itemCode: string) { }

  async sendBackOffice() {
    if (!this.formValid()) {
      return;
    }

    this.loadingMessage = "Fatura Oluşturuluyor...";
    this.loadingVisible = true;

    let saveAccountResult = await this.saveAccountMapping();
    if (!saveAccountResult) {
      //this.glb.notice("Hesap Kaydetme İşleminde Bir Sorun Oluştur.", "info")
      //  this.loadingMessage = "Fatura Oluşturuluyor...";
      this.loadingVisible = false;
      return;
    }

    let saveInventoryResult = await this.saveInventoryMapping();
    if (!saveInventoryResult) {
      this.glb.notice("Stok Kaydetme İşleminde Bir Sorun Oluştur.", "info");
      this.loadingVisible = false;
      return;
    }
    this.invoice.userId = this.ls.userInfo.name;
    this.invoice.editUser = this.ls.userInfo.name;
    // this.invoice.taxTotal = this.roundNumber(this.invoice.invoiceRows.reduce((sum, item) => sum + (item.taxTotal), 0))
    // this.invoice.grandTotal = this.roundNumber(this.invoice.invoiceRows.reduce((sum, item) => sum + (item.total), 0)) -  this.invoice.discountTotal
    this.invoice.stock = true;


    this.invoice.invoiceRows.map((x) => {
      x.code = x.code || x.externalCode;
    });
    debugger

     let invModel : any = this.invoice
     delete invModel._id
    this.publicService
      .upsert({
        _db: this.iSet.settings.licanceId,
        col: "invoices",
        data: invModel,
        filter : {uuid : this.invoice.uuid}
      })
      .then((o) => {
        console.log("saveInvoiceWithKerzzApi OK", o);


        setTimeout(() => {
          this.invoiceService
            .sendDigitalInvoiceToPos(this.invoice.uuid)
            .then((o: any) => {
              console.log("sendDigitalInvoiceToPos", o);
              this.loadingVisible = false;
              if (o.error) {
                this.glb.notice(o.error.message, "warning");
              } else {
                if (o.statusCode == "200") {
                  this.invoiceService.upsert(this.invoice);
                  this.modalService.close("backOfficeModal");
                  this.glb.notice("İşlem Başarı ile tamamlandı.", "success");
                } else {
                  this.glb.notice(o.message, "warning");
                }
              }
            });
        }, 3000);

        // this.invoice.userId = this.ls.userInfo.name
        // this.invoice.editUser = this.ls.userInfo.name

      });
  }
  // MAT2022000009321
  getInventoryMapping() {
    this.units = [];
    this.invoice.invoiceRows.forEach((invRow, index) => {
      this.getMappingForItem(invRow, invRow.code, index, false);
      this.units.push(this.mockUnits);
    });
  }


  async getHistory(inventoryCode)

  {
    let data = {
      "storeId": this.ls.getActiveLicance().orwiStore.id,
      "invoiceNumber": this.invoice.invoiceNumber,
      "accountCode": this.invoice.account.externalCode,
      "invoiceType": 0,
      "inventoryCode": '150.15.0009'
    }
    inventoryCode
    await this.inventoryService
    .serviceRequestPromise('/invoice/getInvoiceHistory',data)
    .then((o) => {
     console.log('historyData',o)
    });
  }


  rowUpdated(e) {
    // e.data.taxTotal = this.roundNumber(this.calculateCellValueKDVTutar(e.data))
    // e.data.total = this.calculateCellValueNetTutar(e.data) +  this.roundNumber(this.calculateCellValueKDVTutar(e.data))
    // e.data.grandTotal = this.calculateCellValueToplamTutar(e.data)
    // e.data.tekifKDV = (this.roundNumber(this.calculateCellValueTekifKDV(e.data)))
  }

  calculateCellValueNetTutar(e) {
    debugger
    if (
      e.quantity === null ||
      e.quantity === undefined ||
      e.unitPrice === null ||
      e.unitPrice === undefined ||
      e.discount === null ||
      e.discount === undefined
    ) {
      return 0;
    }
    const total: number = e.quantity * e.unitPrice;
    return total
  }
  calculateCellValueKDVTutar(e): number {
    // console.log("calculateCellValueKDVTutar:", e);
    debugger
    if (e.taxRate === null || e.taxRate === undefined) {
      return 0;
    }
    //  invoice.grandTotal+discount-taxTotal - tax
    //  invoice.discountTotal/total

    //
    debugger
    // const total : number = this.roundNumber((this.invoice.grandTotal + this.invoice.discountTotal) - this.invoice.taxTotal)
    // const taxRate : any = this.invoice.discountTotal / total
    // const grandTotal : number = (e.quantity * e.unitPrice) * Math.floor(100 * taxRate) / 100;

    // // alert(this.roundNumber(this.invoice.discountTotal - grandTotal))
    // return this.roundNumber(this.invoice.discountTotal - grandTotal)


  }
  calculateCellValueToplamTutar(e) {
    // console.log("calculateCellValueToplamTutar:", e);

    if (
      e.tekifKDV === null ||
      e.tekifKDV === undefined ||
      this.invoice.invoiceType !== "stoppage"
    ) {
      return (

        this.calculateCellValueKDVTutar(e) + this.calculateCellValueNetTutar(e)
      );
    }

    return this.calculateCellValueNetTutar(e) + +e.tekifKDV;
  }

  calculateCellValueDiscountPrice(e): number {
    debugger
    if (e.discount === null || e.discount === undefined || +e.discount <= 0) {
      return e.discountPrice;
    }
    if (e.unitPrice === null || e.unitPrice === undefined) {
      return 0;
    }
    return (e.unitPrice * e.quantity) * (e.discount / 100);
  }
  calculateCellValueDiscount(e): number {
    debugger
    if (
      e.discountPrice === null ||
      e.discountPrice === undefined ||
      e.discountPrice <= 0
    ) {
      return e.discount;
    }
    if (
      e.unitPrice === null ||
      e.unitPrice === undefined ||
      e.unitPrice === 0
    ) {
      return 0;
    }
    let g = e.discountPrice * (100 / e.unitPrice * e.quantity);
    return e.discountPrice * (100 / e.unitPrice * e.quantity);
  }


  roundNumber(amount: number) {
    return Math.round((amount + Number.EPSILON) * 100) / 100
  }




  getMappingForItem(invRow, filterKey = "", index: number, lastTry = false) {
    this.invoiceService
      .getMappingList("0", filterKey, "inventory")
      .subscribe((o) => {
        console.log(o, filterKey);
        if (o.length > 0) {
          invRow.externalCode = o[0].externalCode;
          invRow.warehouseCode = this.wareHouseList[0].warehouseCode;
          invRow.unitName = o[0].unitCode
          // invRow.warehouseCode = o[0].warehouseCode;
        } else {
          if (lastTry) {
            if (!invRow.externalCode) invRow.externalCode = "";
            // if (invRow.warehouseCode != 0) invRow.warehouseCode = 0;
            invRow.warehouseCode = this.wareHouseList[0].warehouseCode;
          } else {
            this.getMappingForItem(invRow, invRow.name, index, true);
          }
        }

        this.getPosInventoryUnits(invRow.externalCode, invRow, index);
        console.log(filterKey, invRow);
      });
  }

  close() {
    this.formClose.emit("close");
  }



  saveInventoryMapping() {
    return new Promise((resolve, reject) => {
      if (this.invoice.invoiceRows.length == 0) {
        this.glb.notice("Fatura Kalemleri Yok.", "info");
        resolve(false);
      }
      if (this.branchCode == "") {
        this.glb.notice("Lütfen şube seçiniz.", "info");
        resolve(false);
      }
      if (this.accountCode == "") {
        this.glb.notice("Lütfen cari seçiniz.", "info");
        resolve(false);
      }

      debugger
      this.invoice.invoiceRows.forEach((invRow) => {
        let inventoryMapping: MappingItem = new MappingItem();
        inventoryMapping.branchCode = "0";
        inventoryMapping.externalCode = invRow.externalCode;
        inventoryMapping.itemType = "inventory";
        inventoryMapping.unitCode = invRow.unitName
        inventoryMapping.warehouseCode = 0;
        inventoryMapping.itemCode = invRow.code || invRow.name;
        invRow.warehouseCode = invRow.warehouseCode;
        invRow.code = invRow.code || invRow.name;
        this.invoiceService.saveMappingItem(inventoryMapping).subscribe((x) => {
          if (x.error) {
            this.glb.notice(x.message, "warning");
            resolve(false);
          } else {
            if (x.statusCode == 200) {
              resolve(true);
            } else {
              this.glb.notice(x.message, "warning");
              resolve(false);
            }
          }
        });
      });
    });
  }

  saveAccountMapping() {
    return new Promise((resolve, reject) => {
      debugger
      if (this.invoice.invoiceRows.length == 0) {
        this.glb.notice("Fatura Kalemleri Yok.", "info");
        resolve(false);
      }
      if (this.branchCode == "") {
        this.glb.notice("Lütfen şube seçiniz.", "info");
        resolve(false)
      }
      if (this.accountCode == "" || this.accountList.length === 0) {
        this.glb.notice("Lütfen cari seçiniz.", "info");
        resolve(false);
      }
      this.accountMapping.itemType = "account";
      this.accountMapping.branchCode = this.branchCode;
      this.accountMapping.externalCode = this.accountCode;
      this.accountMapData.unitCode = ""
      this.accountMapping.itemCode = this.invoice.account.taxNumber;
      //this.accountMapping.warehouseCode = ""
      this.loadingMessage = "Kaydediliyor...";
      this.invoiceService
        .saveMappingItem(this.accountMapping)
        .subscribe((x) => {
          if (x.error) {
            this.glb.notice(x.message, "warning");
            resolve(false);
          } else {
            if (x.statusCode == 200) {
              resolve(true);
            } else {
              this.glb.notice(x.message, "warning");
              resolve(false);
            }
          }
        });
    });
  }

  readonly mockUnits = [
    { id: "KL", name: "Koli" },
    { id: "KT", name: "Kutu" },
    { id: "PK", name: "Paket" },
    { id: "KG", name: "Kilo" },
    { id: "GR", name: "Gram" },
    { id: "LT", name: "Litre" },
    { id: "ML", name: "Mililitre" },
    { id: "CL", name: "Santilitre" },
    { id: "MT", name: "Metre" },
    { id: "AD", name: "Adet" },
    { id: "PR", name: "Porsiyon" },
    { id: "TP", name: "Top" },
    { id: "CV", name: "Çuval" },
    { id: "TN", name: "Teneke" },
  ];
}

interface Unit {
  unitCode: string;
  unitName: string;
  unitMultiplier: number;
  isBaseUnit: boolean;
  isPonderable: boolean;
  isActive: boolean;
}

interface RowItem {
  id: string;
  code: string;
  name: string;
  quantity: number;
  unitPrice: number;
  taxRate: number;
  taxTotal: number;
  total: number;
  grandTotal: number;
  discount: number;
  stoppageAmount: number;
  unitName: string;
  approvedUnit: string;
  approvedQty: number;
  rejectedUnit: string;
  rejectedQty: number;
  rejectReason: string;
  missingQty: number;
  missingUnit: string;
  exceesQty: number;
  exceesUnit: string;
  externalCode: string;
  warehouseCode: string;
}

interface TabItem {
  id: "invoice" | "preview";
  title: string;
}
