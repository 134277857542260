import { Injectable } from '@angular/core';
import { Howl,  } from 'howler';
import notify from 'devextreme/ui/notify';
import { HttpClient } from '@angular/common/http';
import { Exchange } from './dto/global-dto';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public activeRoute

  public showLicanceSelectPopup: boolean = false
  public ssoUsers: any
  public licances: any[]

  public activeLicance = ""
  public activeBrand = ""
  public filteredStores:any

  public lang = "tr"
  public currency = "₺"
  exs : Exchange[] = []
  public modif: Array<{ name: string; qty: number; price: number }> = [];
  public folio: Array<{ name: string; qty: number; price: number, modif: Array<{ name: string; qty: number; price: number }> }> = [];

  customerID: string
  restoranID: string

  constructor(private http : HttpClient) {
    this.customerID = "001"
    this.restoranID = "001"
  }

  serialize(o: object) {
    let smJson = JSON.stringify(o)
    let smObj = JSON.parse(smJson)
    return smObj
  }

  capitalize(str) {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  notice(title: string, type: string, target?: any) {

    let target_toastSettings = {
      message: title,
      type: type,
      displayTime: 3000,
      width: 200,
      height: 50,
      position: { my: "left", at: "left", of: target }
    }


    let center_toastSettings = {
      message: title,
      type: type,
      displayTime: 3000,
      width: 200,
      height: 50,
      position: {
        at: 'center'
      }
    }

    let toastSettings = center_toastSettings
    if (target) {
      toastSettings = target_toastSettings
    }
    notify(toastSettings)

  }

  getExchanges() {
    this.http.get('https://finans.truncgil.com/today.json').toPromise().then(ex => {
      console.log("ex rates", ex)
      let rates = Object.assign(ex);
      let usdR = parseFloat(rates.USD["Satış"].replace(",", ".")) * 1.0115
      let eurR = parseFloat(rates.EUR["Satış"].replace(",", ".")) * 1.0115
      let usd: Exchange = { id: 'usd', rate: usdR, isBase: false, name: "USD", symbol: "$" }
      let eur: Exchange = { id: 'eur', rate: eurR, isBase: false, name: "EUR", symbol: "€" }
      let tl: Exchange = { id: 'tl', rate: 1, isBase: true, name: "TL", symbol: "₺" }

      this.exs = [
        usd, eur, tl
      ]

    })
  }

  notTurkishCharacter(value: string): string {
    let replacedVal = value
      .replace(/ı/g, 'i')
      .replace(/İ/g, 'I')
      .replace(/ü/g, 'u')
      .replace(/Ü/g, 'U')
      .replace(/ş/g, 's')
      .replace(/Ş/g, 'S')
      .replace(/ö/g, 'o')
      .replace(/Ö/g, 'O')
      .replace(/ğ/g, 'g')
      .replace(/Ğ/g, 'G')
      .replace(/ç/g, 'c')
      .replace(/Ç/g, 'C');
    return replacedVal;
  }



  clickSound() {
    const sound = new Howl({
      src: ['/assets/sound/select.wav'],
      volume: 0.2
    });
    sound.play();
  }

  deleteSound() {
    const sound = new Howl({
      src: ['/assets/sound/delete_click.mp3']
    });
    sound.play();
  }


  getLoyaltyPath(index: string) {
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    if (index.substr(index.length - 1, 1) == "-") {
      index = index.substr(0, index.length - 1)
    }
    return "kerzz-loyalty/" +  this.activeLicance + "-" + index
  }

  getPath(index: string) {
    return this.activeLicance + "/" + index
  }

  getMongoPath(index: string) {
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    index = index.replace("/", "-")
    if (index.substr(index.length - 1, 1) == "-") {
      index = index.substr(0, index.length - 1)
    }
    return this.activeLicance + "/" + index
  }





}
